import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import _ from 'lodash'

import Head from "next/head"
import Image from "next/image"

import { LenisScroller } from '../fuselage/animation/lenis-scroller'
import { Reveal } from '../fuselage/animation/reveal'
import { Pop, Fade, Slide } from '../fuselage/animation/animation'

import { QuerySEO } from "../graphql/queries"
import handleSeomaticMeta from "../lib/seomatic"

import { HeroBrand } from "../fuselage/blocks/hero-brand/hero-brand"
import { ImpactFiguresGrid, ImpactFigures, ImpactFigure, ImpactFigureSpacer } from "../fuselage/blocks/impact-figures/impact-figures"
import { Video } from "../fuselage/blocks/video/video"
import { SpeakerCarousel } from '../fuselage/blocks/speaker-carousel/speaker-carousel'
import { SpeakerCard } from "../fuselage/components/speaker-card/speaker-card"
import { LogoGrid } from '../fuselage/layout/logo-grid/logo-grid'

import { HeaderShim } from "../fuselage/global/header/header"
import { LogoBoxCarousel } from "../fuselage/components/logo-box-carousel/logo-box-carousel"
import { SponsorGrid } from "../fuselage/layout/homepage/sponsor-grid/sponsor-grid"
import { FeaturedContent } from "../fuselage/layout/homepage/featured-content/featured-content"
import { PopOver } from '../fuselage/components/popover/popover'
import { ArrowButton } from "../fuselage/components/arrow-button/arrow-button"
import { Button } from "../fuselage/components/button/button"
import { Promo } from "../fuselage/layout/homepage/promo/promo"

import { MontageGrid, MontageImage, MontageBox, MontageSpacer, MontageGraphic } from "../fuselage/layout/montage/montage"
import { NewFeatures } from "../fuselage/layout/homepage/new-features/new-features"
import { AgendaAtAGlance } from "../fuselage/layout/homepage/agenda-at-a-glance/agenda-at-a-glance"


import partnerCSS from '../css/app/partners.module.css'





export default function Homepage ({ seomatic, press, partnerGroups, speakers }) {

    let orderedSpeakers = _.orderBy( speakers, [ speaker => speaker.lastName.toLowerCase()], ['asc'])
    
    let customOrderedSpeakers = _.sortBy(orderedSpeakers, [
        (speaker => speaker.lastName === 'Green' && speaker.firstName === "David" ? 0 : 1),
        (speaker => speaker.lastName === 'Briet' && speaker.firstName === "Jerome" ? 0 : 1),
        (speaker => speaker.lastName === 'Hutchinson' && speaker.firstName === "Guy" ? 0 : 1),
        (speaker => speaker.lastName === 'Mattar' && speaker.firstName === "Haitham" ? 0 : 1),
        (speaker => speaker.lastName === 'du Plessis' && speaker.firstName === "Dr Morne" ? 0 : 1),
        (speaker => speaker.lastName === 'Osei Baffour' && speaker.firstName === "Magdaline" ? 0 : 1),
        (speaker => speaker.lastName === 'Getachew Belete' && speaker.firstName === "Tadiwos" ? 0 : 1),
        (speaker => speaker.lastName === 'Granet' && speaker.firstName === "Olivier" ? 0 : 1),
        (speaker => speaker.lastName === 'Kariuki' && speaker.firstName === "Nyawira" ? 0 : 1),
        (speaker => speaker.lastName === 'Chaudhary' && speaker.firstName === "Rahul" ? 0 : 1),
    ] )



    
    const STRATEGIC_PARTNERS = partnerGroups.find(group => group.id === '138675')
    console.log('STRATEGIC_PARTNERS:', STRATEGIC_PARTNERS)
    



    // console.log('seomatic:', seomatic)
    // console.log('entry:', entry)
    // console.log('entry.speakers:', entry.speakers[0]?.speakers)
    // console.log('partnerGroups:', partnerGroups)
	// console.log('press:', press)


    return (
        <div style={{ width: `100%`, overflow: `hidden` }}>
            <Head>
                { handleSeomaticMeta( seomatic ) }
            </Head>

            <HeaderShim />


            <HeroBrand />


            <NewFeatures />


            <ImpactFiguresGrid>
                <div className="fs-0 fw-600 c-white lh-1"><Reveal>FHS&nbsp;Africa 2024</Reveal> <Reveal delay={0.1}>in numbers</Reveal></div>

                <ImpactFigures>      
                    <ImpactFigure value="450" suffix="+" label="attendees" />
                    <ImpactFigure value="72" suffix="%" label="international delegates" />
                    <ImpactFigureSpacer/>
                    <ImpactFigureSpacer/>
                    <ImpactFigure value="40" suffix="+" label="countries represented" />
                    <ImpactFigure value="16" suffix="%" label="investors" />
                    <ImpactFigure value="90" suffix="+" label="leading industry speakers" />
                    <ImpactFigure value="365" prefix="$" suffix="M" label="in business opportunities and deal volume gained at AHIF" />
                    <ImpactFigure value="50" suffix="+" label="sponsoring companies" />
                </ImpactFigures>
            </ImpactFiguresGrid>



            <Video videoUrl={'https://www.youtube.com/watch?v=s5pKoHX83Nk'} theme={'bg-primary'} bgUpper={'secondary'} bgLower={'bg-wash'} >
                <div className="fs-1 fw-600">
                    <Reveal>FHS Africa</Reveal> <Reveal delay={0.1}>2024 Video</Reveal> <Reveal delay={0.2}>Highlights</Reveal>
                </div>
            </Video>


            <SpeakerCarousel heading={'Featured Speakers'} ctaLink={'/speakers'} ctaLabel={'See all speakers'}>
                {
                    customOrderedSpeakers.map( speaker => (
                        <Pop key={speaker.id}>
                            <SpeakerCard 
                                key={speaker.id}
                                url={`/speakers/profile/${speaker.slug}`}
                                photoUrl={speaker.photoUrl}
                                fullName={`${speaker.firstName} ${speaker.lastName}`}
                                jobTitle={speaker.jobTitle}
                                company={speaker.organization}
                            />
                        </Pop>
                    ))
                }
            </SpeakerCarousel>


            <section className={`block`}>
                <div className='bl-1 bc-primary pl-md'>
                    <Reveal>
                        <p className='fs-1 fw-700 lh-1 c-primary mb-md'>2025 Strategic Partners</p>
                    </Reveal>

                    
                    <LogoGrid>
                        {
                            STRATEGIC_PARTNERS.partners.length &&
                            STRATEGIC_PARTNERS.partners.map( partner => {

                                const handleLogoSize = ( size ) => {

                                    if ( !size ) return

                                    switch (size) {
                                        case 'lg':
                                            return partnerCSS.lg
                                            break
                                        
                                        case 'md':
                                            return partnerCSS.md
                                            break
                                        
                                        case 'sm':
                                            return partnerCSS.sm
                                            break
                                    
                                        default:
                                            break
                                    }
                                }

                                if ( !partner.partnerLogo.length ) return
                                return (
                                    <a key={partner.id} href={`/partners/${partner.slug}`} rel="noreferrer" aria-label="Partner logo">
                                        <div className={`${partnerCSS.logo} ${ handleLogoSize('md') } `}>
                                            <Image 
                                                src={partner.partnerLogo[0].url} 
                                                layout='fill'
                                                alt=""
                                            />
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </LogoGrid>

                    <div className="mt-sm">
                        <Pop><ArrowButton href={`/ahif-sponsors`} theme={{ arrow: `var(--primary)`, border: `var(--primary)`, label: `var(--primary)`}}>See all sponsors and partners</ArrowButton></Pop>
                    </div>


                    {/* <SponsorGrid large>
                        { 
                            partnerGroups.length &&  
                            partnerGroups.map( group => {
                                if ( group.id === '138675' || group.id === '228799' ) return (
                                    <Fade key={ group.id }>
                                        <LogoBoxCarousel 
                                            large
                                            key={ group.id }
                                            heading={ group.title } 
                                            sponsors={ group.partners } 
                                        />
                                    </Fade>
                                )}
                            ) 
                        }
                    </SponsorGrid>

                    <SponsorGrid>
                        { 
                            partnerGroups.length &&  
                            partnerGroups.map( group => {
                                if ( group.id !== '138675' && group.id !== '228799' ) return (
                                    <Fade key={ group.id }>
                                        <LogoBoxCarousel 
                                            key={ group.id }
                                            heading={ group.title } 
                                            sponsors={ group.partners } 
                                        />
                                    </Fade>
                                )}
                            ) 
                        }
                    </SponsorGrid> */}
                    
                    
                </div>
            </section>


            <MontageGrid>

                <MontageBox span={2}>
                    <div className="block">
                        <Reveal>
                            <p className={`fs-1 fw-600 c-white`}>FHS Africa 2024 in&nbsp;photos</p>
                        </Reveal>
                    </div>
                </MontageBox>

                <Pop><MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-01.jpg`} width={650} height={650}/></Pop>
                
                <MontageSpacer backgroundColor={'var(--wash)'}/>

                <Pop><MontageGraphic /></Pop>

                <Pop><MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-04.jpg`} width={650} height={650}/></Pop>
                
                <Pop> <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-02.jpg`} width={1250} height={890} span={2}/></Pop>

                <Pop> <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-03.jpg`} width={1435} height={1050} span={2}/></Pop>

                <Pop><MontageGraphic /></Pop>

                <Pop> <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-05.jpg`} width={650} height={650}/></Pop>
                
                <MontageSpacer/>

                <Pop> <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-07.jpg`} width={650} height={650}/></Pop>

                <Pop> <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-06.jpg`} width={1435} height={1050} span={2}/></Pop>

                <Pop> <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-08.jpg`} width={1435} height={1050} span={2}/></Pop>

                <Pop><MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-09.jpg`} width={650} height={650}/></Pop>

                <MontageSpacer backgroundColor={`var(--wash)`} span={2} />

                <MontageBox span={2}>
                    <div className="block">
                        <Pop inline>

                        <p>
                            <ArrowButton 
                                href={`https://www.flickr.com/photos/benchevents/albums/72177720318292463`} 
                                target={`_blank`} 
                                theme={{ arrow: `white`, border: `white`, label: `white`}}
                            >See more photos</ArrowButton>
                        </p>
                        </Pop>
                    </div>
                </MontageBox>

            </MontageGrid>


            <FeaturedContent posts={press} />


            {/* <PopOver/> */}
            <LenisScroller />
        </div>
    )
}



export async function getStaticProps({ preview, previewData }) {

    const data = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Homepage {
				entry(site: "${process.env.SITE_HANDLE}", id: "15757") {
					... on ${process.env.SITE_HANDLE}Homepage_${process.env.SITE_HANDLE}Homepage_Entry {
						${QuerySEO}
					}
				}
			}
        `
    })
    const pageData = data
    
    const partners = await craftApolloClient( preview, previewData ).query({
        query: gql`
            query Partners {
                entries(site: "${process.env.SITE_HANDLE}", section: "partnerGroups") {
                    ... on partnerGroups_default_Entry {
                        id
                        title
                        partners {
                            ... on partners_partner_Entry {
                                id
                                slug
                                title
                                website
                                partnerLogo {
                                    id
                                    url
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        `
    })
    const partnerData = partners
    
    
	const pressQuery = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query PressRoom {
				entries(
                    site: "${process.env.SITE_HANDLE}", 
                    section: "contentLibrary", 
                    relatedTo: "7702", 
                    limit: 3
                ) {
					... on contentLibrary_article_Entry {
						id
						slug
						title
						postDate
						excerpt
						contentLibraryHero {
							id
							url
							width
							height
						}
						contentTypes {
							... on contentLibrary_Category {
								id
								title
								slug
							}
						}
					}
				}
			}
        `
    })
    const pressData = pressQuery


    const swapcardData = await fetch(
		"https://developer.swapcard.com/event-admin/graphql",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": process.env.SWAPCARD_AUTH_KEY
			},
			body: JSON.stringify({
				query: `
					query eventPerson($eventId: ID!, $cursor: CursorPaginationInput, $filters: [EventPersonFilter!] ) {
						eventPerson(eventId: $eventId, cursor: $cursor, filters:$filters ) {
						nodes {
							id
							type
							firstName
							lastName
							jobTitle
							organization
							photoUrl
							groups {
								id
								name
							}
							withEvent(eventId: $eventId) {
							fields {
								... on SelectField {
									translations {
										value
										language
									}
									definition {
										id
										translations {
											name
											language
										}
											}
										}
									}
									badges {
										... on BadgeBarcode {
											__typename
											barcode
											createdAt
										}
									}
								}
							}
						}
					}
				`,
				variables: {
					"eventId": process.env.SWAPCARD_EVENT_ID_2025,
					"cursor": {
						"first": 200
					},
					"filters": {
                        "eventPeopleIds": [
                            "RXZlbnRQZW9wbGVfMzg2NzYzOTY=",
                            "RXZlbnRQZW9wbGVfMzg2NzY0MTU=",
                            "RXZlbnRQZW9wbGVfMzgyNDYxODk=",
                            "RXZlbnRQZW9wbGVfMzgxNzMyMTc=",
                            "RXZlbnRQZW9wbGVfMzgyMjM2Nzc=",
                            "RXZlbnRQZW9wbGVfMzg1MzY2Mzc=",
                            "RXZlbnRQZW9wbGVfMzc3MDU1NzU=",
                            "RXZlbnRQZW9wbGVfMzgwNDA5MDA=",
                            "RXZlbnRQZW9wbGVfMzczNjkxNzM=",
                            "RXZlbnRQZW9wbGVfMzc3MDgxODQ=",
                            "RXZlbnRQZW9wbGVfMzg3Mzc2MzE="
                        ]
                    }
				}
			})
		}
	)
	const speakerData =  await swapcardData.json()

    // console.dir(pageData)
    // console.log(pageData.data.entry.speakers[0].speakers)


    return { 
		props: { 
            seomatic: pageData.data.entry.seomatic,
            partnerGroups: partnerData.data.entries,
			press: pressData.data.entries,
            speakers: speakerData.data.eventPerson.nodes
		}
	}
}
